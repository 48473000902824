import {Box, Button, Container, Grid, Link, TextField, Typography, useTheme} from "@mui/material";
import * as Yup from "yup";
import {useFormik} from "formik";
import {styled} from "@mui/material/styles";
import {useEffect, useState} from "react";
import ContactUs from "./ContactUs";
import {useTranslation} from "react-i18next";
import OtherService from "../../api/backend/service/OtherService";
import ReCAPTCHA from "react-google-recaptcha";
import FooterLinks from "../Footer/FooterLinks";
import InquiryForm from "../InquiryForm";


const headerBgStyle = {
    background: '#1e1e1e url(/assets/img/bg-footer.svg)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    // backgroundBlendMode: 'multiply',
};

// slouzi k stylovani textfildu
const StyledTextField = styled(TextField)({
    "& .MuiInputLabel-root": {
        right: 0,
    },
    "& .MuiInputLabel-shrink": {
        margin: "0 auto",
        position: "absolute",
        right: "0",
        left: "0",
        top: "-10px",
        color: '#f0c031'
    },
    // "& .MuiOutlinedInput-root.Mui-focused": {
    //     "& legend ": {
    //         display: "none"
    //     }
    // }
    "& .MuiFormHelperText-root.Mui-error": {
        // backgroundColor: "transparent !important",
        margin: 0,
        backgroundColor: "#000",
        paddingLeft: "14px",
        paddingRight: "14px",
    }
});
const ContactForm = () => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <section style={headerBgStyle} id="contact">
            <Container maxWidth="xl"
                       sx={{
                           // display: 'flex',
                           paddingTop: '32px',
                           paddingBottom: '32px',
                           flexDirection: 'column',
                           alignItems: 'flex-start',
                           justifyContent: 'center',
                           height: '100%'
                       }}
            >

                <Grid container spacing={2} pt={5}>
                    <Grid item sm={5}>
                        <Typography variant={"h3"} id={"inquiry"}
                                    sx={{
                                        fontSize: '28px',
                                        lineHeight: '36px',
                                        fontWeight: 'bold',
                                        color: '#fff',
                                        textTransform: 'uppercase',
                                        borderBottom: `4px solid ${theme.palette.primary.main}`,
                                        width: 'fit-content',
                                        // marginX: 'auto',
                                        position: 'relative',
                                        '@media (min-width: 1200px)': {
                                            fontSize: '3xl',
                                        },
                                    }}
                        >
                            {t('inquiry_form.form_name')}
                        </Typography>
                        <Grid container>
                            <Grid md={8}>
                                <InquiryForm infotextColor={"#fff"}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={3} xs={12}>
                        <Box pb={10}>
                            <FooterLinks />
                        </Box>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <ContactUs/>
                    </Grid>
                </Grid>
                <Typography pt={5} sx={{
                    marginX: 'auto',
                    position: 'relative',
                    color: '#fff',
                    textAlign: 'center',
                }}>
                    Copyright &copy; {new Date().getFullYear()}&nbsp;
                    <strong>Strojparts, s.r.o.</strong>
                </Typography>
                {/*</Box>*/}
            </Container>
        </section>
    );
}

export default ContactForm;
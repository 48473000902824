import Slider from "../components/Slider";
import Brands from "../components/Brands";
import InfoContainer from "../components/InfoContainer";
import Gallery from "../components/Gallery";
import React from "react";
import RandomProductPage from "./RandomProductPage";
import {Helmet} from "react-helmet";

const HomePage = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Náhradní díly pro stavební a manipulační techniku</title>
                <meta
                    name="description"
                    content="Náhradní díly pro stavební a manipulační techniku"
                />
            </Helmet>
            <Slider/>
            <Brands/>
            <InfoContainer/>
            {/*<Gallery />*/}
            <RandomProductPage />
        </>
    );
}

export default HomePage;
import Container from "@mui/material/Container";
import {Box, Button, Chip, Collapse, Divider, InputAdornment, Typography} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import {Fragment, useEffect, useState} from "react";
import {CatalogCategory, Category, Product} from "../api/generated";
import {useQuery} from "@tanstack/react-query";
import {NotFoundError} from "../api/backend/NotFound";
import CategoryService from "../api/backend/service/CategoryService";
import Grid from "@mui/material/Grid";
import ProductService from "../api/backend/service/ProductService";
import ProductList from "./ProductList";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from '@mui/icons-material/Search';
import ListItemIcon from "@mui/material/ListItemIcon";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import {ExpandLess, ExpandMore, StarBorder} from "@mui/icons-material";
import FactoryIcon from '@mui/icons-material/Factory';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";

const ProductCatalogPage = () => {
    const { t } = useTranslation();

    const { data, status, isLoading, error, isError} =
        useQuery<CatalogCategory, NotFoundError>({ queryKey: ['category'], queryFn: () => CategoryService.getAllCatalogCategory()})
    const [showAll, setShowAll] = useState(false);
    const [showAllBrands, setShowAllBrands] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState<Category | null>(null);
    const [search, setSearch] = useState<string | null>(null);

    const [openBrands, setOpenBrands] = useState(true);
    const [openOtherBrands, setOpenOtherBrands] = useState(false);
    const [openParts, setOpenParts] = useState(false);
    const [openFilters, setOpenFilters] = useState(false);
    const [openAccessories, setOpenAccessories] = useState(false);

    const brands = showAllBrands ? data?.brands : (data?.brands ? data.brands.slice(0, 10) : []);
    const otherBrands =  data?.otherBrands ? data.otherBrands : [];
    const parts =  showAll ? data?.parts : (data?.parts ? data.parts.slice(0, 10) : []);
    const filters =  data?.filters ? data.filters : [];
    const accessories =  data?.accessories ? data.accessories : [];


    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        // Extrahujeme query parametry z aktuální URL
        const params = new URLSearchParams(location.search);
        const searchParam = params.get('search'); // Například ?search=value
        const categoryParam = params.get('category'); // Například ?category=value
        setSearch(searchParam); // Nastavíme do state


        if (categoryParam && data) {
            const foundCategory = [
                ...(data.brands || []),
                ...(data.otherBrands || []),
                ...(data.parts || []),
                ...(data.filters || []),
                ...(data.accessories || [])
            ].find(category => category.name?.toLowerCase() === categoryParam.toLowerCase());
            console.log("param categry", categoryParam, foundCategory)
            setSelectedCategory(foundCategory || null);
        }

    }, [location, data]);



    const handleCategoryClick = (category: Category) => {
        console.log("vybraná kategorie", category);
        setSelectedCategory(category);
        const params = new URLSearchParams(location.search);
        if (category.name) {
            params.set('category', category.name.toLowerCase());
            navigate(`${location.pathname}?${params.toString()}`, { replace: true });
        }
    };

    const handleDeleteCategory = () => {
        const params = new URLSearchParams(location.search);
        params.delete('category');
        navigate(`${location.pathname}?${params.toString()}`, { replace: true });

        setSelectedCategory(null);
    };

    const handleSearch = () => {

    };

    const handleClickBrands = () => {
        setOpenBrands(!openBrands);
    };

    const handleClickOtherBrands = () => {
        setOpenOtherBrands(!openOtherBrands);
    };

    const handleClickParts = () => {
        setOpenParts(!openParts);
    };

    const handleClickFilters = () => {
        setOpenFilters(!openFilters);
    };

    const handleClickAccessories = () => {
        setOpenAccessories(!openAccessories);
    };


    return (
        <Container maxWidth={false} sx={{
            paddingTop: '64px',
            paddingBottom: '64px',
            boxShadow: 'inset 0 0 #0000, inset 0 25px 35px -12px rgba(0, 0, 0, 0.25), ' +
                'inset 0 -25px 35px -12px rgba(0, 0, 0, 0.25)',
        }}>
            <Container maxWidth="xl">
                <Typography variant={"h4"}>{t('product_catalog_page.product_catalog')}</Typography>
                <Grid container spacing={2} pt={5}>
                    <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
                        <Box sx={{ width: '100%', bgcolor: 'background.paper', marginRight: '20px' }}>
                            <List component="nav" aria-label="brands-categories">
                                <ListItemButton onClick={handleClickBrands}>
                                    <ListItemIcon  sx={{ minWidth: '35px' }} >
                                        <FactoryIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={t('product_catalog_page.manufacturers')} />
                                    {openBrands ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>
                                <Collapse in={openBrands} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {brands?.map((category, index) => (
                                            <Fragment key={category.name}>
                                                <ListItem disablePadding>
                                                    <ListItemButton  onClick={() => handleCategoryClick(category)} sx={{ pl: 8 }}>
                                                        <ListItemText primary={category.name} />
                                                    </ListItemButton>
                                                </ListItem>
                                            </Fragment>
                                        ))}
                                        {(data?.brands?.length ?? 0) > 10 && (
                                            <Button
                                                onClick={() => setShowAllBrands(!showAllBrands)}
                                                sx={{ width: '100%' }}
                                            >
                                                {showAllBrands ? 'Méně výrobců' : 'Více výrobců'}
                                            </Button>
                                        )}
                                    </List>
                                </Collapse>
                            </List>
                        </Box>
                        <Box sx={{ width: '100%', bgcolor: 'background.paper', marginRight: '20px' }}>
                            <List component="nav" aria-label="brands-categories">
                                <ListItemButton onClick={handleClickOtherBrands}>
                                    <ListItemIcon  sx={{ minWidth: '35px' }} >
                                        <FactoryIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={t('product_catalog_page.other_manufacturers')} />
                                    {openOtherBrands ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>
                                <Collapse in={openOtherBrands} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {otherBrands?.map((category, index) => (
                                            <Fragment key={category.name}>
                                                <ListItem disablePadding>
                                                    <ListItemButton  onClick={() => handleCategoryClick(category)} sx={{ pl: 8 }}>
                                                        <ListItemText primary={category.name} />
                                                    </ListItemButton>
                                                </ListItem>
                                            </Fragment>
                                        ))}
                                    </List>
                                </Collapse>
                            </List>
                        </Box>
                        <Box sx={{ width: '100%', bgcolor: 'background.paper', marginRight: '20px' }}>
                            <List component="nav" aria-label="brands-categories">
                                <ListItemButton onClick={handleClickParts}>
                                    <ListItemIcon  sx={{ minWidth: '35px' }} >
                                        <SettingsApplicationsIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={t('product_catalog_page.other_parts')} />
                                    {openParts ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>
                                <Collapse in={openParts} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {parts
                                            ?.slice()
                                            .sort((a, b) => {
                                                const sequenceA = a.sequence ?? Number.MAX_SAFE_INTEGER;
                                                const sequenceB = b.sequence ?? Number.MAX_SAFE_INTEGER;

                                                if (sequenceA === 0 && sequenceB === 0) {
                                                    return 0;
                                                } else if (sequenceA === 0) {
                                                    return 1;
                                                } else if (sequenceB === 0) {
                                                    return -1;
                                                } else {
                                                    return sequenceA - sequenceB;
                                                }
                                            })
                                            .map((category, index) => (
                                                <Fragment key={category.name}>
                                                    <ListItem disablePadding>
                                                        <ListItemButton onClick={() => handleCategoryClick(category)} sx={{ pl: 8 }}>
                                                            <ListItemText primary={category.name} />
                                                        </ListItemButton>
                                                    </ListItem>
                                                </Fragment>
                                            ))}
                                        {(data?.parts?.length ?? 0) > 10 && (
                                            <Button
                                                onClick={() => setShowAll(!showAll)}
                                                sx={{ width: '100%' }}
                                            >
                                                {showAll ? 'Méně kategorií' : 'Více kategorií'}
                                            </Button>
                                        )}
                                    </List>
                                </Collapse>
                            </List>
                        </Box>
                        <Box sx={{ width: '100%', bgcolor: 'background.paper', marginRight: '20px' }}>
                            <List component="nav" aria-label="brands-categories">
                                <ListItemButton onClick={handleClickFilters}>
                                    <ListItemIcon  sx={{ minWidth: '35px' }} >
                                        <SettingsApplicationsIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={t('product_catalog_page.filters')} />
                                    {openFilters ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>
                                <Collapse in={openFilters} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {filters?.map((category, index) => (
                                            <Fragment key={category.name}>
                                                <ListItem disablePadding>
                                                    <ListItemButton  onClick={() => handleCategoryClick(category)} sx={{ pl: 8 }}>
                                                        <ListItemText primary={category.name} />
                                                    </ListItemButton>
                                                </ListItem>
                                            </Fragment>
                                        ))}
                                    </List>
                                </Collapse>
                            </List>
                        </Box>
                        <Box sx={{ width: '100%', bgcolor: 'background.paper', marginRight: '20px' }}>
                            <List component="nav" aria-label="brands-categories">
                                <ListItemButton onClick={handleClickAccessories}>
                                    <ListItemIcon  sx={{ minWidth: '35px' }} >
                                        <SettingsApplicationsIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={t('product_catalog_page.accessories')} />
                                    {openAccessories ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>
                                <Collapse in={openAccessories} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {accessories?.map((category, index) => (
                                            <Fragment key={category.name}>
                                                <ListItem disablePadding>
                                                    <ListItemButton  onClick={() => handleCategoryClick(category)} sx={{ pl: 8 }}>
                                                        <ListItemText primary={category.name} />
                                                    </ListItemButton>
                                                </ListItem>
                                            </Fragment>
                                        ))}
                                    </List>
                                </Collapse>
                            </List>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={8} md={9} lg={10} xl={10}>
                        <Grid container spacing={2} justifyContent="space-between">
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                {selectedCategory && (
                                    <Chip
                                        label={`${t('product_catalog_page.category')}  ${selectedCategory.name}`}
                                        color="primary"
                                        // sx={{ marginBottom: 2 }}
                                        onDelete={handleDeleteCategory}
                                    />
                                )}
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} justifyContent={"flex-end"} display={"flex"}>
                                <TextField
                                    variant="standard"
                                    placeholder={t('product_catalog_page.catalog_num')}
                                    onChange={(e) => setSearch(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton >
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Box pt={2}>
                            <ProductList category={selectedCategory}  findProduct={search}/>
                        </Box>
                    </Grid>
                </Grid>



            </Container>
        </Container>
    );
}

export default ProductCatalogPage;
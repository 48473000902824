import {useInfiniteQuery, useQuery, useQueryClient} from "@tanstack/react-query";
import {Category, Product} from "../api/generated";
import {NotFoundError} from "../api/backend/NotFound";
import ProductService from "../api/backend/service/ProductService";
import {Grid, Card, CardContent, CardMedia, Typography, CircularProgress, makeStyles, Button} from '@mui/material';
import {useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {PRODUCT_DIR} from "../resources/constants";


interface ProductListProps {
    category: Category | null;
    findProduct: string | null;
}

const ProductList = ( { category, findProduct } : ProductListProps ) => {
    const batchSize = 25; // Počet produktů načítaných v jedné dávce
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const directory = PRODUCT_DIR || "https://strojparts.cz/assets/products/img/";


    const {
        data,
        error,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
        refetch,
    } = useInfiniteQuery<Product[], NotFoundError>({
        queryKey: ['products', category?.id, findProduct],
        retry: 3,
        queryFn: async ({ pageParam = 0 }) => {
            // Přetypování pageParam na number nebo undefined
            console.log("categoz", category)
            const offset = typeof pageParam === 'number' ? pageParam : undefined;
            return ProductService.getCatalogProducts(batchSize, offset, category?.id, findProduct);
        },
        initialPageParam: 0,
        getNextPageParam: (lastPage, allPages) => {
            console.log("getNextPageParam", lastPage, allPages)
            // Vypočítáme offset pro další stránku
            const nextPage = allPages.length * batchSize;
            return lastPage.length === batchSize ? nextPage : undefined;
        },
        staleTime: Infinity
    });

    const loadMore = () => {
        console.log("load more", hasNextPage)
        if (hasNextPage) {
            fetchNextPage();
        }
    };
    useEffect(() => {
        console.log("useEffect", category)
        queryClient.removeQueries({ queryKey: ['products'], type: 'inactive' })
    }, [category, findProduct, fetchNextPage]);



    // Spojení všech načtených stránek produktů
    const allProducts = data?.pages.flatMap(page => page) ?? [];
    const filteredData = category
        ? allProducts.filter(product =>
            product.category?.some(cat => cat.id === category.id)
        )
        : allProducts;


    // TODO vyresit scrollovani na produkt pri navrati z detailu



    return (
        <>
            <Grid container spacing={2}>
                {filteredData?.filter(product => {
                    if (findProduct && findProduct.trim() !== "" && (product.note || product.name)) {
                        const findProductLowerCase = findProduct.toLowerCase();
                        return (product.note && product.note.toLowerCase().includes(findProductLowerCase)) ||
                            (product.name && product.name.toLowerCase().includes(findProductLowerCase));
                    } else {
                        return true;
                    }
                }).map(product => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={product.id}>
                        <a href={`/product/${product.id}/${product.path}`} style={{textDecoration: "none"}}>
                            <Card sx={{
                                height: '100%',
                                cursor: 'pointer',
                                transition: 'transform 0.2s, box-shadow 0.2s',
                                '&:hover': {
                                    transform: 'scale(1.02)',
                                    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
                                },
                            }}
                                /*onClick={() => handleClickProduct(product)}*/>
                                <CardMedia
                                    component="img"
                                    height="140"
                                    image={product.productPicture && product.productPicture.length > 0 ? directory+product.productPicture[0].filename : '/assets/img/logo_product_no_img.jpg'}
                                    alt={product.name}
                                    sx={{height: 350}}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" sx={{fontWeight: 700}}>
                                        {product.name}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" sx={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        display: '-webkit-box',
                                        WebkitLineClamp: 2,
                                        WebkitBoxOrient: 'vertical',
                                        fontWeight: 600
                                    }}>
                                        {product.note}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </a>
                    </Grid>
                ))}
            </Grid>

            <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px', paddingTop: '50px'}}>
                {isFetching ? <CircularProgress /> : hasNextPage && (
                    <Button variant="contained" onClick={loadMore}>
                        Načíst další produkty
                    </Button>
                )}
                {!data && !isFetching && (
                    <div>Produkty nenalezeny.</div>
                )}
            </div>


        </>
    );
}

export default ProductList;
import {Box, Button, Chip, CircularProgress, Stack, Typography, useTheme} from "@mui/material";
import {Category, Product} from "../../api/generated";
import {useLocation, useParams} from "react-router-dom";
import Container from "@mui/material/Container";
import {useQuery} from "@tanstack/react-query";
import {NotFoundError} from "../../api/backend/NotFound";
import CategoryService from "../../api/backend/service/CategoryService";
import ProductService from "../../api/backend/service/ProductService";
import Grid from "@mui/material/Grid";
import Slider, {Settings} from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {useRef, useState} from "react";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import {PRODUCT_DIR} from "../../resources/constants";
import {useTranslation} from "react-i18next";
import InquiryForm from "../InquiryForm";
import {Helmet} from "react-helmet";


const ProductDetail = () => {
    const { t } = useTranslation();
    const {productId, name} = useParams();
    const theme = useTheme();
    const {data, status, isLoading, error, isError} =
        useQuery<Product, NotFoundError>({queryKey: ['product', productId], queryFn: () => ProductService.getCatalogProduct(productId)})

    const directory = PRODUCT_DIR || "https://strojparts.cz/assets/products/img/";

    // slider
    const [sliderRef, setSliderRef] = useState<Slider | null>(null);
    const [navSliderRef, setNavSliderRef] = useState<Slider | null>(null);
    //
    // const sliderRef = useRef(null);
    // const navSliderRef = useRef(null);

    // lightbox
    const [index, setIndex] = useState(-1);
    const lightboxImages = (data?.productPicture || []).map((img) => ({ src: `${directory}${img.filename}` }));


    const sliderSettings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        infinite: true,
        asNavFor: navSliderRef || undefined,
        nextArrow: <SampleNextArrow/>,
        prevArrow: <SamplePrevArrow/>
    }

    const navSliderSettings = {
        slidesToShow: data && data.productPicture && data.productPicture.length > 0 ? Math.min(4, data.productPicture.length) : 0,
        slidesToScroll: 1,
        asNavFor: sliderRef || undefined,
        focusOnSelect: true,
        infinite: data && data.productPicture && data.productPicture.length > 0,
        rows: 1,
    };



    function SampleNextArrow(props: any) {
        const {className, style, onClick} = props;
        return (
            <NavigateNextIcon className={className}
                              style={{...style, display: "block", color: theme.palette.primary.main}}
                              onClick={onClick}/>
        );
    }

    function SamplePrevArrow(props: any) {
        const {className, style, onClick} = props;
        return (
            <NavigateBeforeIcon className={className}
                                style={{...style, display: "block", color: theme.palette.primary.main}}
                                onClick={onClick}/>
        );
    }

    const firstImage = data?.productPicture?.[0]?.filename;

    const category = ["TEST1", "TEST2", "TEST2", "TEST2", "TEST2", "TEST2", "TEST2", "TEST2", "TEST2", "TEST2"]

    return (


        <Container maxWidth={false} sx={{
            paddingTop: '64px',
            paddingBottom: '64px',
            boxShadow: 'inset 0 0 #0000, inset 0 25px 35px -12px rgba(0, 0, 0, 0.25), ' +
                'inset 0 -25px 35px -12px rgba(0, 0, 0, 0.25)',
        }}>
            <Container maxWidth="xl">
                {isLoading ? (
                    <Box sx={{display: 'flex', justifyContent: "center", marginTop: '20px'}}>
                        <CircularProgress/>
                    </Box>
                ) : isError ? (
                    <div>
                        Objevila se chyba při načítání produktu.
                    </div>
                ) : (
                    <Box>
                        <Helmet>
                            <meta charSet="utf-8"/>
                            <title>{data?.name} - Strojparts.cz</title>
                            <meta name="description" content={`Kvalita: ${data?.quality}, Katalogové číslo: ${data?.note}`}/>
                            <meta name="robots" content="index, follow"/>
                            <link rel="canonical" href={`https://strojparts.cz/product/${data?.id}/${data?.path}`}/>

                            {/* Open Graph tags */}
                            <meta property="og:site_name" content="Strojparts.cz"/>
                            <meta property="og:title" content={`${data?.name} - Strojparts.cz`}/>
                            <meta property="og:description" content={`Kvalita: ${data?.quality}, Katalogové číslo: ${data?.note}`}/>
                            <meta property="og:type" content="product"/>
                            <meta property="og:url" content={`https://strojparts.cz/product/${data?.id}/${data?.path}`}/>
                            <meta property="og:locale" content="cs_CZ"/>
                            {firstImage && (
                                <>
                                    <meta property="og:image" content={`${directory}${firstImage}`}/>
                                    <meta property="og:image:alt" content={`${data?.name} - obrázek produktu`}/>
                                </>
                            )}

                            {/* Twitter Card tags */}
                            <meta name="twitter:card" content="summary_large_image"/>
                            <meta name="twitter:title" content={`${data?.name} - Strojparts.cz`}/>
                            <meta name="twitter:description" content={`Kvalita: ${data?.quality}, Katalogové číslo: ${data?.note}`}/>
                            {firstImage && <meta name="twitter:image" content={`${directory}${firstImage}`}/>}
                        </Helmet>
                        <Box>
                        <Typography variant={"h1"} sx={{
                                fontSize: '24px',
                                lineHeight: '28px',
                                fontWeight: 'bold',
                                borderBottom: `2px solid ${theme.palette.primary.main}`,
                                textTransform: 'uppercase',
                                display: 'inline-block',
                            }}>{data?.name}</Typography>
                        </Box>
                        <Grid container spacing={4} pt={3}>
                            <Grid item xs={12} sm={12} md={5} lg={4} xl={4}>
                                <Slider {...sliderSettings} ref={(slider) => setSliderRef(slider)}>
                                    {(data?.productPicture && data.productPicture.length > 0) ? (
                                        data.productPicture.map((img, index) => {
                                            return (
                                                <Box
                                                    key={index}
                                                    component="img"
                                                    onClick={() => setIndex(index)}
                                                    src={`${directory}${img.filename}`}
                                                    alt={img.description}
                                                    sx={{
                                                        objectFit: 'contain',
                                                        marginRight: '16px',
                                                    }}
                                                />
                                            );
                                        })
                                    ) : (
                                        <Box
                                            component="img"
                                            src="/assets/img/logo.svg"
                                            alt="Placeholder"
                                            sx={{
                                                objectFit: 'contain',
                                                marginRight: '16px',
                                            }}
                                        />
                                    )}
                                </Slider>
                                <Slider {...navSliderSettings} ref={(slider) => setNavSliderRef(slider)}>
                                    {(data?.productPicture && data.productPicture.length > 0) ? (
                                        data.productPicture.map((img, index) => {
                                            return (
                                                <Box
                                                    key={index}
                                                >
                                                    <Box
                                                        component="img"
                                                        src={`${directory}${img.filename}`}
                                                        alt={img.description}
                                                        sx={{
                                                            height: {
                                                                xl: '40px',
                                                                xs: '40px',
                                                            },
                                                            width: {
                                                                xl: '135px',
                                                                xs: '135px',
                                                            },
                                                            objectFit: 'contain',
                                                            // marginRight: '16px',
                                                        }}
                                                    />
                                                </Box>
                                            );
                                        })
                                    ) : (
                                        <Box
                                            key="placeholder"
                                            component="img"
                                            src="/assets/img/logo.svg"
                                            alt="Placeholder"
                                            sx={{
                                                height: {
                                                    xl: '40px',
                                                    xs: '40px',
                                                },
                                                width: {
                                                    xl: '135px',
                                                    xs: '135px',
                                                },
                                                objectFit: 'contain',
                                                // marginRight: '16px',
                                            }}
                                        />
                                    )}
                                </Slider>
                            </Grid>
                            <Grid item xs={12} sm={7} md={4} lg={4} xl={4}>
                                <Typography variant={"h2"} sx={{
                                    fontSize: '18px',
                                    lineHeight: '28px',
                                    display: 'inline-block',
                                    fontWeight: 'bold',
                                    marginRight: '8px',
                                }}>
                                    {t('product_detail_page.availability')}:
                                </Typography>
                                {/*TODO implementovat skladovou dostupnost z DB*/}
                                <Typography variant={"h2"}
                                            sx={{
                                                fontSize: '18px',
                                                lineHeight: '28px',
                                                display: 'inline-block',
                                            }}>
                                    Skladem u dodavatele
                                </Typography>
                                <br/>
                                <Typography variant={"h2"}
                                            sx={{
                                                fontSize: '18px',
                                                lineHeight: '28px',
                                                display: 'inline-block',
                                                fontWeight: 'bold',
                                                marginRight: '8px',
                                            }}>{t('product_detail_page.quality')}:</Typography>
                                <Typography variant={"h2"}
                                            sx={{
                                                fontSize: '18px',
                                                lineHeight: '28px',
                                                display: 'inline-block',
                                            }}>{data?.quality}</Typography>
                                <br/>
                                <Typography variant={"h2"} sx={{
                                    fontSize: '18px',
                                    lineHeight: '28px',
                                    display: 'inline-block',
                                    fontWeight: 'bold',
                                    marginRight: '8px',
                                }}>
                                    {t('product_detail_page.catalog_num')}:
                                </Typography>
                                <Typography variant={"h2"}
                                            sx={{
                                                fontSize: '18px',
                                                lineHeight: '28px',
                                                display: 'inline-block',
                                            }}>
                                    {data?.note}
                                </Typography>
                                <Box pt={4}>
                                    <Typography variant={"h3"}
                                                sx={{
                                                    fontSize: '20px',
                                                    lineHeight: '36px',
                                                    fontWeight: 'bold',
                                                    textTransform: 'uppercase',
                                                    borderBottom: `4px solid ${theme.palette.primary.main}`,
                                                    width: 'fit-content',
                                                    // marginX: 'auto',
                                                    position: 'relative',
                                                    '@media (min-width: 1200px)': {
                                                        fontSize: '3xl',
                                                    },
                                                }}
                                    >
                                        {t('inquiry_form.form_name_product')}
                                    </Typography>
                                    <InquiryForm vin={data?.note} infotextColor={"#000"}/>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={5} md={3} lg={4} xl={4}>
                                {/*Zobrazim pouze kdyz je kompatibilni znacka ze sequence 10 (výrobce stavebních strojů)*/}
                                {data?.category?.length && data.category.filter(category => category.sequence === 10).length > 0 && (
                                    <>
                                        <Typography variant={"h2"} sx={{
                                            fontSize: '18px',
                                            lineHeight: '28px',
                                            display: 'inline-block',
                                            fontWeight: 'bold',
                                            marginRight: '8px',
                                        }}>
                                            {t('product_detail_page.compatibility_brands')}
                                        </Typography>
                                        <Stack direction="row" spacing={1} flexWrap="wrap">
                                            {data.category.filter(category => category.sequence === 10).map((category, index) => (
                                                <Chip
                                                    key={index}
                                                    label={category.name}
                                                    color="primary"
                                                    variant="outlined"
                                                    style={{margin: '5px', flex: "none"}}
                                                />
                                            ))}
                                        </Stack>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                )}
                <Lightbox
                    index={index}
                    slides={lightboxImages}
                    open={index >= 0}
                    close={() => setIndex(-1)}
                />
            </Container>
        </Container>
    );
}
export default ProductDetail;